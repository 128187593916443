@import '~ethos-design-system/src/components/Media/Media.scss';

.wrapper {
  padding-bottom: 48px;
  @include for-tablet-and-up {
    padding-bottom: 100px;
  }
}

.heading {
  color: #054742;
  text-align: center;
  font-family: Cambon;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;

  strong {
    color: #f26d00;
    font-weight: 700;
  }
  p {
    margin: 0 0 40px;
  }

  @include for-laptop-and-up {
    font-size: 44px;
    line-height: 50px;
    p {
      margin-bottom: 64px;
    }
  }
}
.bottomContent {
  margin-top: 40px;

  .button {
    max-width: 216px;
    margin: 40px auto 0;
  }
  @include for-laptop-and-up {
    margin-top: 60px;
  }
}
