@import '~ethos-design-system/src/components/Media/Media.scss';

$overlay: 120px;
$mobileOverlay: 60px;

.valuePropsWillsContainer {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 60px 60px 0;

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1320px;
    width: 100%;
    padding: 100px 80px;
    border-radius: 24px;
    background-image: url('https://res.cloudinary.com/getethos/image/upload/v1724229000/Frame_1000007452_bw13sf.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    gap: 64px;

    @include for-phone-and-tablet {
      background-image: linear-gradient(
        284deg,
        #f3f7f7 0%,
        #e6efee 100%
      ) !important;
      padding: 48px 20px;
    }

    .willsHeading {
      text-align: center;
      color: #272727;
      font-family: Cambon;
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 80px;
      letter-spacing: -1.2px;

      strong {
        color: #056257;
        font-weight: 600;
      }

      p {
        margin: 0;
      }

      @include for-phone-and-tablet {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .columnContainer {
      display: flex;
      gap: 20px;
      flex-direction: row;

      @include for-phone-and-tablet {
        flex-direction: column;
      }

      .nslpBodyCopy {
        color: #272727;
        text-align: center;
        font-family: NewTheinhardt;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;

        p {
          margin: 0;
        }
      }
    }
  }

  @include for-phone-and-tablet {
    display: block;
    padding: 0 16px 0 !important;
    gap: 48px;
  }
}

.heading {
  font-family: 'Cambon';
  font-style: normal;
  font-size: 32px;
  line-height: 140%;
  font-weight: 700;
  color: #054742;
  margin-bottom: var(--Space-40);

  @include for-laptop-and-up {
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 60px;
  }
  @include for-phone-only {
    margin-bottom: 48px;
    margin-top: 16px;
  }

  strong {
    color: var(--BrandDarkSalamander);
  }

  p {
    margin: 0;
  }
}

.columnTitle {
  font-family: 'Cambon';
  font-weight: 700;
  font-size: 22px;
  line-height: 110%;
  margin-bottom: var(--Space-12);
  p {
    margin: 0;
  }
}

.columnBodyCopy {
  font-family: 'Cambon';
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
}

.cta {
  display: inline-block;
  background: var(--BrandCypress);
  color: var(--White);
  padding: var(--Space-16) var(--Space-80);
  border-radius: 8px;
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: 500;
  font-size: 17.5px;
  line-height: 24px;

  @include for-laptop-and-up {
    padding: var(--Space-16) var(--Space-64);
  }

  &:hover,
  &:active {
    opacity: 0.85;
  }
}

.insetTreatment {
  &:before {
    box-shadow: 30px -30px 0 0 #f3faf6 !important;

    @include for-laptop-and-up {
      box-shadow: 60px -60px 0 0 #f3faf6 !important;
    }
  }
}

.nslpBodyCopy {
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.mobileCTASpace {
  height: 0px;
  @include for-phone-only {
    height: 16px;
  }
}

.minimalTheme {
  .heading {
    color: var(--BrandCypress);
    font-family: Theinhardt;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    margin: 0 0 64px;

    @include for-phone-only {
      font-size: 20px;
      letter-spacing: 1.6px;
      margin: 0 0 48px;
    }
  }

  .columnTitle {
    color: #272727;
    font-family: Theinhardt;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.448px;

    @include for-phone-only {
      font-size: 24px;
      letter-spacing: -0.24px;
      margin-bottom: 12px;
    }
  }

  .columnBodyCopy {
    color: #272727;
    font-family: Theinhardt;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.3px;
    line-height: 32px;

    @include for-phone-only {
      font-size: 20px;
      line-height: 28px; /* 140% */
    }
  }

  .cta {
    width: 360px;
    background-color: var(--BrandDarkSalamander);
    color: #fff;
    font-family: Theinhardt;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.24px;
    padding: 24px 48px;
    line-height: 1;

    @include for-phone-only {
      width: 100%;
    }
  }

  .ctaSubtext {
    font-size: 20px;
    font-weight: 400;

    p {
      margin: 0;
    }
  }
}

.willsCtaV2_2 {
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;

  @include for-laptop-and-up {
    width: 500px;
    flex-direction: row;
  }
}

.trustageValueProps {
  background: radial-gradient(
      107.78% 104.72% at 50% 39.15%,
      rgba(239, 246, 243, 0.23) 0%,
      #dbede5 100%
    ),
    #fff;
}
